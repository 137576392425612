import React, { useCallback, useEffect, useState } from 'react';

import {
  Result,
  Spin
} from 'antd';

import './App.css';
import { ZoomMtg } from '@zoom/meetingsdk';
import Config from "./config/Config";
// import Discussion from './Discussion';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

function App() {
  const [loading, setLoading] = useState(true);
  const [isStreamOpen, setIsStreamOpen] = useState(true);
  const [payloadZoom, setPayloadZoom] = useState(null);

  const startMeeting = useCallback(() => {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: `${Config.EDUPARX_SITE}/oti/class/${payloadZoom.otiSlug}/training`,
      patchJsMedia: true,
      success: (success) => {
        console.log(success)

        let isCheckPermissionVideoAudio = false;
        setInterval(() => {
          const bJoin = document.getElementsByClassName('preview-join-button');
          if (bJoin[0]) {
            bJoin[0].disabled = true;
            bJoin[0].innerText = "Please allow camera & microphone";
            bJoin[0].style.backgroundColor = "#c6c6c6";
            bJoin[0].style.cursor = "not-allowed";
            // setTimeout(() => {
            //   bJoin[0].disabled = false;
            //   bJoin[0].click();
            //   window.location.reload();
            // }, 2000);
            if (!isCheckPermissionVideoAudio) {
              navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
                if (stream.active) {
                  bJoin[0].disabled = false;
                  bJoin[0].click();
                  isCheckPermissionVideoAudio = true;
                }
              });
            }
            // navigator.permissions.query({name: 'microphone'}).then((result) => {
            //   console.log(result);
            // });
          }

          // remove chat
          const listElement = document.getElementsByClassName('footer-button-base__button-label');
          if (listElement.length > 0) {
            // console.log(listElement);
            Array.from(listElement).forEach((v, index) => {
              if (v.innerText === 'Chat') {
                v.parentNode.parentNode.parentNode.style.display = 'none';
              }

              if (v.innerText === 'Record') {
                v.parentNode.parentNode.style.display = 'none';
              }

              if (v.innerText === 'AI Companion') {
                v.parentNode.parentNode.style.display = 'none';
              }

              if (v.innerText === 'Show Captions') {
                v.parentNode.parentNode.style.display = 'none';
              }

            });
          }

          // remove action participants
          const actionParticipant = document.getElementsByClassName('participants-section-container__participants-footer-bottom window-content-bottom');
          if (actionParticipant[0]) {
            actionParticipant[0].style.display = 'none';
          }

          // remove action participants
          const actionTopLeft = document.getElementsByClassName('meeting-info-container--left-side');
          if (actionTopLeft[0]) {
            actionTopLeft[0].style.display = 'none';
          }

          // remove more button
          const moreButton = document.getElementById('moreButton');
          if (moreButton) {
            moreButton.style.display = 'none';
          }

          // participant more button
          const participanMoreButton = document.getElementsByClassName('participants-item__right-section--buttons');
          if (participanMoreButton[0]) {
            participanMoreButton[0].style.display = 'none';
          }

          // leave button
          const leaveButton = document.getElementsByClassName('footer__leave-btn');
          if (leaveButton[0]) {
            leaveButton[0].style.display = 'none';
          }

          // fullscreen button
          const fullscreenButton = document.getElementsByClassName('meeting-info-container--right-side');
          if (fullscreenButton[0]) {
            fullscreenButton[0].style.display = 'none';
          }
        }, 250);

        ZoomMtg.join({
          signature: payloadZoom.signature,
          sdkKey: payloadZoom.sdkKey,
          meetingNumber: payloadZoom.meetingNumber,
          passWord: payloadZoom.passWord,
          userName: payloadZoom.userName,
          userEmail: payloadZoom.userEmail,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }, [payloadZoom])

  const initMeeting = useCallback(() => {
    if (payloadZoom.otiSlug) {
      const formData = new FormData();
      formData.append("otiSlug", payloadZoom.otiSlug);

      fetch(`${Config.BASE_API_2}/v1/zoom/check-meeting`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${payloadZoom.bearerToken}`
        },
        body: formData
      }).then(res => res.json()).then(({ info }) => {
        if (info.oti_stream_enable) {
          setLoading(false);
          setTimeout(() => {
            startMeeting();
          }, 1000);
        } else {
          setLoading(false);
          setIsStreamOpen(false);
        }
      }).catch(error => {
        setLoading(false);
        console.error(error)
      })
    } else {
      setLoading(false);
    }
  }, [payloadZoom, startMeeting]);

  useEffect(() => {
    if (!payloadZoom) {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');

      if (token && token !== '') {
        try {
          const payloadToken = JSON.parse(atob(token));
          setPayloadZoom(payloadToken);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else {
        setLoading(false);
      }
    } else {
      setTimeout(() => {
        initMeeting();
      }, 1000)
    }
  }, [payloadZoom, initMeeting]);

  const noValidToken = (
    <div>
      <Result
        status="403"
        title="Akses Ditolak"
        subTitle="Maaf, anda tidak diizinkan mengakses halaman ini"
      />
    </div>
  );

  const noValidSlug = (
    <div>
      <Result
        status="error"
        title="Token Tidak Valid"
        subTitle="Maaf, token anda tidak valid"
      />
    </div>
  );

  const streamClose = (
    <div>
      <Result
        status="error"
        title="Stream Tutup"
        subTitle="Maaf, stream telah ditutup"
      />
    </div>
  );

  const mainStream = (
    <div>
      {/* <Discussion payloadZoom={payloadZoom} /> */}
    </div>
  );

  return (
    <div className="App">
      {loading ?
        <Spin tip="Loading" size="large" style={{
          paddingTop: window.innerHeight
        }}>
          <div />
        </Spin>
        :
        (isStreamOpen ? (<main>
          {payloadZoom && payloadZoom.signature
            ?
            (payloadZoom.otiSlug ? mainStream : noValidSlug)
            :
            noValidToken}
        </main>) : streamClose)
      }
    </div>
  );
}

export default App;
