import React, { memo, useState, useEffect } from "react";
import Viewer from "react-viewer";

const ImageViewer = memo(({ files, styleName, children }) => {
	const [visible, setVisible] = useState(false);
	const [images, setImages] = useState([]);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (files && files.length > 0) {
			let newFiles = [];
			files.map((r) => {
				r.alt = r.hasOwnProperty('alt') ? r.alt : r.name || r.file_name;
				r.src = r.hasOwnProperty('src') ? r.src : r.url || r.file_url;
				newFiles.push(r);
				return r;
			})
			setImages(newFiles);
		}
	}, [files]);

	return (
		<React.Fragment>
			{images.map((img, index) => (
				<img
					src={img.src}
					alt=""
					key={index}
					style={{
						width: '3rem',
            cursor: 'pointer',
            marginRight: 5
					}}
					onClick={() => {
						setVisible(true);
						setIndex(index);
					}} draggable="false" />
			))}
			<Viewer activeIndex={index} visible={visible} onClose={() => setVisible(false)} images={images} />
		</React.Fragment>
	);
});

export default ImageViewer;