import React from 'react';
import AppFullScreen from './App';
import AppDiscussion from './App-New-Iframe';

function App() {

  const searchParams = new URLSearchParams(window.location.search);
  const discussionView = searchParams.get('discussion');

  return (
    <div>
      {discussionView === 'no' ? <AppFullScreen /> : <AppDiscussion />}
    </div>
  );
}

export default App;
