import React, { memo, useCallback, useEffect, useState } from 'react';

import {
  Avatar,
  Card,
  Divider,
  Input,
  Upload,
  Button,
  notification,
  Pagination,
  FloatButton,
  Drawer,
  Space,
  Spin
} from 'antd';
import {
  CameraOutlined,
  FrownOutlined,
  HeartOutlined,
  HeartTwoTone,
  PaperClipOutlined,
  CommentOutlined,
  RedoOutlined
} from "@ant-design/icons";

import './App.css';
import Config from "./config/Config";
import ImageViewer from "./ImageViewer";
import dayjs from "dayjs";
import dayjsUpdateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(dayjsUpdateLocale)

dayjs.updateLocale('en', {
  weekdays: [
    "Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"
  ],
  months: [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli",
    "Agustus", "September", "Oktober", "November", "Desember"
  ]
});

const Reply = memo(({ payloadZoom, getDiscussion, uuid = null }) => {
  const [api, contextHolder] = notification.useNotification();

  const acceptedFileFormat = ".png, .jpg, .jpeg";
  const maxFileSize = Math.pow(512, 2) * 2;

  const [message, setMessage] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const bytesToSize = (bytes) => {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  }

  const uploadConfig = {
    name: "attach_file",
    accept: acceptedFileFormat.replace(" ", ""),
    showUploadList: true,
    listType: "picture-card",
    multiple: true,
    onChange: (info) => {
      const { file } = info;
      const { type, size, status } = file;

      if (status === "removed") return true;

      const allowedType = ["image/jpeg", "image/jpg", "image/png"];
      const isAllowedType = allowedType.indexOf(type);

      if (isAllowedType >= 0) {
        const isSizeAllowed = size <= maxFileSize;
        if (isSizeAllowed) {
          let newFiles = uploadedFiles.map((r) => r);
          newFiles.push(file);
          setUploadedFiles(newFiles);
          return true;
        } else {
          api['error']({
            message: 'Error',
            description: `Ukuran file maskimal ${bytesToSize(
              maxFileSize
            )} !`
          });
        }
      } else {
        api['error']({
          message: 'Error',
          description: `Format file tidak valid !`
        });
      }
      return false;
    },
    fileList: uploadedFiles ? uploadedFiles : [],
    beforeUpload: (file) => {
      return false;
    },
    onRemove: (file) => {
      if (uploadedFiles && uploadedFiles.length > 0) {
        let newFiles = [];
        uploadedFiles.map((row) => {
          if (file.uid !== row.uid) newFiles.push(row);
          return row;
        });
        setUploadedFiles(newFiles);
      }
    },
  };

  const uploadTemplate = (
    <div>
      <CameraOutlined style={{ fontSize: 30 }} />
      <br />
      Max {bytesToSize(maxFileSize)}
    </div>
  );

  const onReplyQuestion = () => {
    setPostLoading(true);

    const formData = new FormData();
    formData.append("content", message);

    if (showUpload && uploadedFiles && uploadedFiles.length > 0) {
      for (let i = 0; i < uploadedFiles.length; i++) {
        const file = uploadedFiles[i];
        formData.append("files[]", file);
      }
    }

    let apiUrl = `${Config.BASE_API}/api/oti/my/class/${payloadZoom.otiSlug}/discuss`
    if (uuid) {
      apiUrl = `${apiUrl}/${uuid}/reply`
    }

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${payloadZoom.bearerToken}`
      },
      body: formData
    }).then(res => res.json()).then(response => {
      getDiscussion();
      if (response.code === 200) {
        setMessage("");
        setUploadedFiles([]);
        setPostLoading(false);
        setShowUpload(false);
      }
    }).catch(error => {
      getDiscussion();
      console.error(error)
    })
  }

  return (<div className="gx-media-body">
    {contextHolder}
    {uuid && <Divider />}
    <Input.TextArea
      rows={2}
      placeholder={uuid ? "Tulis komentar anda" : "Apa yang ingin anda tanyakan ?"}
      value={message}
      onChange={({ currentTarget }) =>
        setMessage(currentTarget.value)
      }
    />
    <Button
      type={showUpload ? "primary" : "default"}
      size="small"
      onClick={() => setShowUpload(!showUpload)}
      style={{
        marginTop: 5
      }}
    >
      <PaperClipOutlined />
      {uploadedFiles && uploadedFiles.length > 0
        ? `${uploadedFiles.length} file`
        : "Lampirkan file"}
    </Button>
    {showUpload && <Upload
      {...uploadConfig}
    >
      {uploadTemplate}
    </Upload>}
    <div style={{
      width: '100%',
      textAlign: 'right'
    }}>
      <Button
        type="primary"
        loading={postLoading}
        onClick={() => onReplyQuestion()}
        disabled={
          message &&
            message.trim() &&
            !postLoading
            ? false
            : true
        }
      >
        Kirim
      </Button>
    </div>
  </div>);
})

function Discussion({ payloadZoom }) {
  const [discussions, setDiscussions] = useState([]);
  const [totalDiscussion, setTotalDiscussion] = useState(0);
  const [pageDiscussion, setPageDiscussion] = useState(1);
  const [sizeDiscussion] = useState(10);

  const [loading, setLoading] = useState(false);
  const [showDiscussion, setShowDiscussion] = useState(false);

  const getDiscussion = useCallback(() => {
    let params = {
      page: pageDiscussion,
      rows: sizeDiscussion,
      sort: 'dis_created_at',
      order: 'desc',
    };

    setLoading(true);
    const queryParams = new URLSearchParams(params);
    fetch(`${Config.BASE_API_2}/v1/oti/my/class/${payloadZoom.otiSlug}/discuss?${queryParams.toString()}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${payloadZoom.bearerToken}`
      }
    }).then(res => res.json()).then(({ results }) => {
      setDiscussions(results.rows);
      setTotalDiscussion(results.total);
      setLoading(false);
      console.log(showDiscussion);
    }).catch(error => {
      setLoading(false);
      console.error(error);
    });
  }, [payloadZoom, pageDiscussion, sizeDiscussion, showDiscussion]);

  useEffect(() => {
    getDiscussion();
  }, [getDiscussion]);

  useEffect(() => {
    setInterval(() => {
      document.getElementById('discuss-reload-btn').click();
      console.log('refreshing');
    }, 60000 * 1);
  }, []);

  function formatDateID(date) {
    return dayjs(date).format('dddd, DD MMMM YYYY HH:mm');
  }

  function handleChangePagination(page) {
    setPageDiscussion(page);
  }

  function handleLike(like, uuid) {
    const apiUrl = `${Config.BASE_API}/api/oti/my/class/${payloadZoom.otiSlug}/discuss/${uuid}`;
    fetch(`${apiUrl}/${like ? 'like' : 'unlike'}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${payloadZoom.bearerToken}`
      }
    }).then(res => res.json()).then(() => {
      getDiscussion();
    }).catch(error => {
      getDiscussion();
      console.error(error)
    });
  }

  return (
    <div>
      <Drawer
        title="Diskusi"
        open={showDiscussion}
        onClose={() => {setShowDiscussion(false)}}
        style={{
          backgroundColor: '#f9f9f9',
        }}
        extra={
          <Space>
            <Button id="discuss-reload-btn" type="primary" icon={<RedoOutlined/>} onClick={() => { getDiscussion() }}>
              Reload
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <div style={{
            overflowY: 'scroll',
            height: window.innerHeight - (160 + 150),
            maxHeight: window.innerHeight - (160 + 150)
          }}>
            {discussions.length > 0 ?
              discussions.map((row) => (
                <Card key={row.uuid} style={{ marginBottom: 10 }}>
                  <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
                    <Avatar
                      className="gx-size-40"
                      style={{
                        marginBottom: 0,
                        marginRight: 5
                      }}
                      src={row.created_user.mem_picture_url}
                    />
                    <div className="gx-media-body">
                      <h5 style={{ margin: 0 }}>
                        {row.created_user.mem_fullname}
                      </h5>
                      <p className="discuss-date" style={{ margin: 0 }}>
                        {formatDateID(row.created_at)}
                      </p>
                    </div>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: row.content }} />
                  {row.files && row.files.length > 0 ? (
                    <ImageViewer files={row.files} />
                  ) : null}
                  <div style={{ display: 'flex' }}>
                    <p
                      className="discuss-date"
                      onClick={() => handleLike(!row.is_liked, row.uuid)}
                      style={{
                        cursor: 'pointer'
                      }}
                    >
                      {row.is_liked ? <HeartTwoTone twoToneColor="#eb2f96" /> : <HeartOutlined />}
                      <span style={{ marginLeft: 5, marginRight: 10, fontSize: 13 }}>
                        {row.total_like} Suka
                      </span>
                    </p>
                    <p
                      className="discuss-date"
                    >
                      <i className="fa fa-commenting-o"></i>
                      <span style={{ marginLeft: 5, marginRight: 10, fontSize: 13 }}>
                        {row.children.length} Komentar
                      </span>
                    </p>
                  </div>
                  {row.children.length > 0 && <div style={{ paddingLeft: '3rem' }}>
                    {row.children.map((rowChildren) => (
                      <div key={rowChildren.uuid}>
                        <Divider />
                        <div className="gx-media gx-wall-user-info gx-flex-nowrap gx-align-items-center">
                          <Avatar
                            className="gx-size-40"
                            style={{
                              marginBottom: 0,
                              marginRight: 5
                            }}
                            src={rowChildren.created_user.mem_picture_url}
                          />
                          <div className="gx-media-body">
                            <h5 style={{ margin: 0 }}>
                              {rowChildren.created_user.mem_fullname}
                            </h5>
                            <p className="discuss-date" style={{ margin: 0 }}>
                              {formatDateID(rowChildren.created_at)}
                            </p>
                          </div>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: rowChildren.content }} />
                        {rowChildren.files && rowChildren.files.length > 0 ? (
                          <ImageViewer files={rowChildren.files} />
                        ) : null}
                        <div style={{ display: 'flex' }}>
                          <p
                            className="discuss-date"
                            onClick={() => handleLike(!rowChildren.is_liked, rowChildren.uuid)}
                            style={{
                              cursor: 'pointer'
                            }}
                          >
                            {rowChildren.is_liked ? <HeartTwoTone twoToneColor="#eb2f96" /> : <HeartOutlined />}
                            <span style={{ marginLeft: 5, marginRight: 10, fontSize: 13 }}>
                              {rowChildren.total_like} Suka
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>}
                  <Reply payloadZoom={payloadZoom} uuid={row.uuid} getDiscussion={getDiscussion} />
                </Card>
              ))
              :
              <div className="discuss-date" style={{ textAlign: 'center' }}>
                <br />
                <FrownOutlined style={{ fontSize: 40 }} />
                <br />
                <span>Belum Ada Diskusi</span>
                <br />
                <br />
              </div>
            }
          </div>
        </Spin>
        <div style={{
          marginTop: 5,
          textAlign: "right"
        }}>
          <Card size="small">
            <Pagination
              showSizeChanger={false}
              current={pageDiscussion}
              onChange={handleChangePagination}
              pageSize={sizeDiscussion}
              total={totalDiscussion} />
          </Card>
        </div>
        <div>
          <Card size="small">
            <Reply payloadZoom={payloadZoom} getDiscussion={getDiscussion} />
          </Card>
        </div>
      </Drawer>
      {!showDiscussion && <FloatButton
        icon={<CommentOutlined />}
        type="primary"
        shape="square"
        description="Discussion"
        style={{
          width: 120,
          height: 52,
          right: 0,
          bottom: 0
        }}
        onClick={() => {setShowDiscussion(true)}}
      />}
    </div>
  );
}

export default Discussion;
