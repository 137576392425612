import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Result,
  Spin
} from 'antd';

import Discussion from './Discussion';
import './App.css';
import Config from "./config/Config";

function App() {
  const [loading, setLoading] = useState(true);
  const [isStreamOpen, setIsStreamOpen] = useState(true);
  const [payloadZoom, setPayloadZoom] = useState(null);

  const initMeeting = useCallback(() => {
    if (payloadZoom.otiSlug) {
      const formData = new FormData();
      formData.append("otiSlug", payloadZoom.otiSlug);

      fetch(`${Config.BASE_API_2}/v1/zoom/check-meeting`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${payloadZoom.bearerToken}`
        },
        body: formData
      }).then(res => res.json()).then(({ info }) => {
        if (info.oti_stream_enable) {
          setLoading(false);
        } else {
          setLoading(false);
          setIsStreamOpen(false);
        }
      }).catch(error => {
        setLoading(false);
        console.error(error)
      })
    } else {
      setLoading(false);
    }
  }, [payloadZoom]);

  useEffect(() => {
    if (!payloadZoom) {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');

      if (token && token !== '') {
        try {
          const payloadToken = JSON.parse(atob(token));
          setPayloadZoom(payloadToken);
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      } else {
        setLoading(false);
      }
    } else {
      setTimeout(() => {
        initMeeting();
      }, 1000)
    }
  }, [payloadZoom, initMeeting]);

  useEffect(() => {
    window.addEventListener('resize', function () {
      document.getElementById('iframe-main-stream').height = window.innerHeight;
    });
  }, []);

  const mainStream = (
    <div>
      <iframe id="iframe-main-stream" title="Zoom Meeting" src={window.location.toString().replace('discussion=yes', 'discussion=no')} width={window.innerWidth} height={window.innerHeight}/>
      <Discussion payloadZoom={payloadZoom} />
    </div>
  );

  const noValidToken = (
    <div>
      <Result
        status="403"
        title="Akses Ditolak"
        subTitle="Maaf, anda tidak diizinkan mengakses halaman ini"
      />
    </div>
  );

  const noValidSlug = (
    <div>
      <Result
        status="error"
        title="Token Tidak Valid"
        subTitle="Maaf, token anda tidak valid"
      />
    </div>
  );

  const streamClose = (
    <div>
      <Result
        status="error"
        title="Stream Tutup"
        subTitle="Maaf, stream telah ditutup"
      />
    </div>
  );

  return (
    <div className="App">
      {loading ?
        <Spin tip="Loading" size="large" style={{
          paddingTop: window.innerHeight
        }}>
          <div />
        </Spin>
        :
        (isStreamOpen ? (<main>
          {payloadZoom && payloadZoom.signature 
          ? 
          (payloadZoom.otiSlug ? mainStream : noValidSlug)
          :
          noValidToken}
        </main>) : streamClose)
      }
    </div>
  );
}

export default memo(App);
